import React from "react";
import { Route, Redirect } from "react-router-dom";

// Function to check if the user is authenticated
const isAuthenticated = () => {
  const token = localStorage.getItem("authToken");
  const logoutTime = localStorage.getItem("logoutTime");

  // Check if token exists and logoutTime hasn't expired
  if (token && logoutTime && Date.now() < parseInt(logoutTime)) {
    return true; // User is authenticated and session is still valid
  }

  // If session expired, clear storage and return false
  localStorage.removeItem("authToken");
  localStorage.removeItem("userRole");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("logoutTime");

  return false; // Session expired, force logout
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" /> // Redirect to login if session expired
        )
      }
    />
  );
};

export default PrivateRoute;

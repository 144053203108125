import React, { useState, useEffect } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const history = useHistory();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  const [isLoading, setIsLoading] = useState(false);

  // Function to log out user (clears localStorage & redirects to login)
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("logoutTime");
    history.push("/login");
  };

  // Function to check if logout time has passed
  const checkLogoutTime = () => {
    const logoutTime = localStorage.getItem("logoutTime");

    if (logoutTime && Date.now() > parseInt(logoutTime)) {
      handleLogout(); // Log out the user
    }
  };

  // Automatically check logout time every minute
  useEffect(() => {
    checkLogoutTime(); // Run check on page load

    const interval = setInterval(checkLogoutTime, 60000); // Check every 60s
    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await axios.post(`${backendUrl}/login/`, {
        username,
        password,
      });

      if (response.status === 200 && response.data.message === "Login successful") {
        localStorage.setItem("authToken", response.data.token);
        localStorage.setItem("userRole", response.data.role);
        localStorage.setItem("isAdmin", response.data.admin ? "true" : "false");
        localStorage.setItem("userName", response.data.userName);

        // Set logout time (2 hours from now)
        const logoutTime = Date.now() + 2 * 60 * 60 * 1000;
        localStorage.setItem("logoutTime", logoutTime);

        history.push("/dashboard");
      } else {
        setError("Invalid credentials");
      }
    } catch (error) {
      setError("Login failed. Please try again.");
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleLogin}>
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={isLoading}>
          {isLoading ? "Logging in..." : "Login"}
        </button>
      </form>
    </div>
  );
};

export default Login;

import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import {Link, useHistory} from 'react-router-dom';
import './Bookmakers.css';
import { FaPencilAlt, FaSave, FaTimes, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import logo from '../assets/EnterpriseLogo.png';
import BookmakersModal from './BookmakersModal';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Bookmakers = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookmakers, setBookmakers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });

  // For row editing
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const [updatedValues, setUpdatedValues] = useState({});

  const fetchBookmakers = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/bookmakers/get/list`);
      if (response.data && Array.isArray(response.data)) {
        setBookmakers(response.data);
      } else {
        console.error('API Error: Unexpected data format', response.data);
      }
    } catch (error) {
      console.error('Error fetching Bookmakers:', error);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchBookmakers();
  }, [fetchBookmakers]);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const enableEditingRow = (index) => {
    if (editingRowIndex === index) {
      setEditingRowIndex(null);
      setUpdatedValues({});
    } else {
      setEditingRowIndex(index);
      setUpdatedValues({ ...bookmakers[index] });
    }
  };

  const handleFieldChange = (field, value) => {
    setUpdatedValues((prevValues) => ({
      ...prevValues,
      [field]: value,
    }));
  };

  const saveUpdatedRow = async () => {
    if (editingRowIndex === null) return;

    try {
      const updatedBookmaker = updatedValues;
      // Call update endpoint on backend
      await axios.post(`${backendUrl}/bookmakers/update`, {
        _id: updatedBookmaker._id,
        ...updatedBookmaker,
      });

      setBookmakers((prevBookmakers) =>
        prevBookmakers.map((bm, idx) =>
          idx === editingRowIndex ? { ...updatedBookmaker } : bm
        )
      );

      setEditingRowIndex(null);
      setUpdatedValues({});
    } catch (error) {
      console.error('Error updating row:', error);
    }
  };

  const toggleSort = (key) => {
    setSortConfig((prevSort) => ({
      key,
      direction: prevSort.key === key && prevSort.direction === "asc" ? "desc" : "asc",
    }));
  };

  const sortedBookmakers = () => {
    let sortedData = [...bookmakers];

    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        const aValue = a[sortConfig.key] || "";
        const bValue = b[sortConfig.key] || "";

        if (!isNaN(aValue) && !isNaN(bValue)) {
          return sortConfig.direction === "asc"
            ? Number(aValue) - Number(bValue)
            : Number(bValue) - Number(aValue);
        }

        return sortConfig.direction === "asc"
          ? aValue.toString().localeCompare(bValue.toString())
          : bValue.toString().localeCompare(aValue.toString());
      });
    }
    return sortedData;
  };

  const getSortIcon = (column) => {
    if (sortConfig.key !== column) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
    <Link to="/dashboard" className="dashboard-logo-link">
      <img className="dashboard-logo" src={logo} alt="Logo" />
    </Link>
        <Link to="/dashboard" className="dashboard-header-link">
      <h1>The Enterprise</h1>
    </Link>
      </div>

      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => history.push('/dashboard')}>Home</button>
        <button className="nav-button" onClick={() => history.push('/accounts')}>Accounts</button>
        <button className="nav-button" onClick={() => history.push('/wallets')}>Wallets</button>
        <button className="nav-button" onClick={() => history.push('/transactions')}>Transactions</button>
        <button className="nav-button" onClick={() => history.push('/bets')}>Bets</button>
        <button className="nav-button" onClick={() => history.push('/logout')}>Logout</button>
      </div>
      <hr className="dashboard-line" />

      <div className="filters-add-account-container">
        <input
          type="text"
          className="search-input"
          placeholder="Search Bookmakers..."
          value={searchTerm}
          onChange={handleSearch}
        />
        <button className="add-account-button" onClick={() => setIsModalOpen(true)}>
          + Add Bookmaker
        </button>
      </div>

      <BookmakersModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

      <div className="bookmaker-container">
        {isLoading ? (
          <p>Loading Bookmakers...</p>
        ) : (
          <table className="bookmaker-table">
            <thead>
              <tr>
                {["uniqueName", "max_balance", "priming", "markets", "useable", "no_active_accounts"].map((col) => (
                  <th key={col} onClick={() => toggleSort(col)} style={{ cursor: "pointer" }}>
                    {col.replace(/_/g, " ")} {getSortIcon(col)}
                  </th>
                ))}
                <th>KYC Needs</th>
                <th>Comments</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {sortedBookmakers()
                .filter((bm) => bm.uniqueName.toLowerCase().includes(searchTerm.toLowerCase()))
                .map((bm, index) => (
                  <tr key={bm._id}>
                    {["uniqueName", "max_balance", "priming", "markets", "useable", "no_active_accounts"].map((field) => (
                      <td key={field}>
                        {editingRowIndex === index ? (
                          <input
                            type="text"
                            value={updatedValues[field] || ""}
                            onChange={(e) => handleFieldChange(field, e.target.value)}
                          />
                        ) : (
                          field === "useable" ? (bm.useable ? "Yes" : "No") : (bm[field] || 'N/A')
                        )}
                      </td>
                    ))}
                    <td>
                      <div>
                        {["bank_account_accepted", "proof_of_funds", "video_verification", "call_required", "liveness_check_required"].map((key) => (
                          <div key={key}>
                            {key.replace(/_/g, " ")}:{" "}
                            <span style={{ color: bm[key] ? "green" : "red" }}>
                              {bm[key] ? "✓" : "✗"}
                            </span>
                          </div>
                        ))}
                      </div>
                    </td>
                    <td>
                      {editingRowIndex === index ? (
                        <input
                          type="text"
                          value={updatedValues.comments || ""}
                          onChange={(e) => handleFieldChange("comments", e.target.value)}
                        />
                      ) : (
                        <span>{bm.comments || 'N/A'}</span>
                      )}
                    </td>
                    <td>
                      {editingRowIndex === index ? (
                        <>
                          <FaSave className="edit-icon" onClick={saveUpdatedRow} title="Save changes" />
                          <FaTimes className="edit-icon cancel-icon" onClick={() => enableEditingRow(index)} title="Cancel editing" />
                        </>
                      ) : (
                        <FaPencilAlt className="edit-icon" onClick={() => enableEditingRow(index)} title="Edit row" />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Bookmakers;

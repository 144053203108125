import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import axios from "axios";
import "./Wallets.css";
import logo from "../assets/EnterpriseLogo.png";
import WalletModal from "./WalletModal";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Wallets = () => {
  const history = useHistory();
  const [wallets, setWallets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingWallets, setLoadingWallets] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);
  const [hideZeroBalance, setHideZeroBalance] = useState(true);

  // Get admin status from localStorage
  const isAdmin = localStorage.getItem("isAdmin") === "true";

  // Fetch wallets from the backend
  const fetchWallets = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/wallet/query`);
      if (response.data.success) {
        let fetchedWallets = response.data.result.result;

        if (Array.isArray(fetchedWallets)) {
          // Exclude inactive wallets
          fetchedWallets = fetchedWallets.filter(
            (wallet) => wallet.status !== "inactive"
          );

          if (hideZeroBalance) {
            fetchedWallets = fetchedWallets.filter(
              (wallet) => parseFloat(wallet.current_balance) > 0
            );
          }

          // Sort wallets by balance in descending order
          fetchedWallets = fetchedWallets.sort(
            (a, b) => parseFloat(b.current_balance) - parseFloat(a.current_balance)
          );

          setWallets(fetchedWallets);
          calculateTotalBalance(fetchedWallets);
        } else {
          console.error("Expected an array, but got:", fetchedWallets);
          setWallets([]);
        }
      } else {
        console.error("API Error:", response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching wallets:", error);
      setIsLoading(false);
      setWallets([]);
    }
  }, [hideZeroBalance]);

  // Calculate total balance
  const calculateTotalBalance = (wallets) => {
    const total = wallets
      .filter((wallet) => wallet.status !== "inactive")
      .reduce(
        (acc, wallet) => acc + parseFloat(wallet.current_balance || 0),
        0
      );
    setTotalBalance(total.toFixed(2));
  };

  useEffect(() => {
    fetchWallets();
  }, [fetchWallets]);

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    history.push("/logout");
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const updateWallet = async (walletAddress) => {
    setLoadingWallets((prev) => [...prev, walletAddress]);
    try {
      await axios.post(`${backendUrl}/wallet/update`, {
        address: walletAddress,
        network: "btc",
      });
      fetchWallets();
    } catch (error) {
      console.error(`Error updating wallet ${walletAddress}:`, error);
    }
    setLoadingWallets((prev) => prev.filter((addr) => addr !== walletAddress));
  };

  const updateAllWallets = async () => {
    setLoadingWallets(wallets.map((wallet) => wallet.address_id));
    try {
      const response = await axios.post(`${backendUrl}/wallet/update/all`);
      if (response.data && response.data.result.status === "success") {
        await fetchWallets();
      } else {
        console.error("Error updating all wallets:", response.data);
      }
    } catch (error) {
      console.error("Error updating all wallets:", error);
    }
    setLoadingWallets([]);
  };

  const handleCheckboxChange = () => {
    setHideZeroBalance((prev) => !prev);
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
    <Link to="/dashboard" className="dashboard-logo-link">
      <img className="dashboard-logo" src={logo} alt="Logo" />
    </Link>
        <Link to="/dashboard" className="dashboard-header-link">
      <h1>The Enterprise</h1>
    </Link>
      </div>
      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation("/bookmakers")}>
          Bookies
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/accounts")}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/wallets")}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/transactions")}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/bets")}>
          Bets
        </button>
        <button className="nav-button" onClick={handleLogout}>
          Logout
        </button>
      </div>
      <hr className="dashboard-line" />

      {/* Fixed Filter Checkbox */}
      <div className="fixed-filter">
        <input
          type="checkbox"
          checked={hideZeroBalance}
          onChange={handleCheckboxChange}
          id="hideZeroBalance"
        />
        <label htmlFor="hideZeroBalance">Hide 0 balance</label>
      </div>

      <div className="action-buttons">
        <button className="add-wallet-button" onClick={openModal}>
          + Add Wallet
        </button>
        <button className="update-all-button" onClick={updateAllWallets}>
          Update All
        </button>
      </div>

      <WalletModal isOpen={isModalOpen} onClose={closeModal} />

      <div className="wallets-container">
        {isLoading ? (
          <p>Loading wallets...</p>
        ) : (
          <table className="wallets-table">
            <thead>
              <tr>
                <th>Wallet Name</th>
                {isAdmin && <th>Current Balance</th>}
                <th>Coin</th>
                <th>Address</th>
                <th>Manager</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {wallets.length > 0 ? (
                wallets.map((wallet) => (
                  <tr key={wallet.wallet_id}>
                    <td>{wallet.wallet_name}</td>
                    {isAdmin && <td>{wallet.current_balance}</td>}
                    <td>{wallet.coin}</td>
                    <td>{wallet.address_id}</td>
                    <td>{wallet.manager}</td>
                    <td>
                      <button
                        onClick={() => updateWallet(wallet.address_id)}
                        disabled={loadingWallets.includes(wallet.address_id)}
                      >
                        {loadingWallets.includes(wallet.address_id) ? (
                          <span className="spinner"></span>
                        ) : (
                          "Update"
                        )}
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={isAdmin ? "6" : "5"}>No wallets found.</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>

      {isAdmin && (
        <div className="total-balance-container-discreet">
          <span>Total Wallet Balance:</span>
          <span className="total-balance-amount-discreet">₿ {totalBalance}</span>
        </div>
      )}
    </div>
  );
};

export default Wallets;

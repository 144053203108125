import React, { useEffect, useState } from 'react';
import './BetsTable.css';
const backendUrl = process.env.REACT_APP_BACKEND_URL;

const BetsTable = () => {
  const [betsByMatch, setBetsByMatch] = useState({});
  const [bannedBookiesByMatch, setBannedBookiesByMatch] = useState({});
  const [expandedMatches, setExpandedMatches] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [stakes, setStakes] = useState({});
  const [betIds, setBetIds] = useState([]);

  useEffect(() => {
    const fetchTodaysBetbuilderBets = async () => {
      try {
        const response = await fetch(`${backendUrl}/bets/football/betbuilders/get_latest/`);
        if (!response.ok) {
          throw new Error("Failed to fetch today's bets");
        }
        const data = await response.json();

        const groupedBets = {};
        const bannedBookiesGrouped = {};
        const ids = [];

        data.forEach((bet) => {
          if (!groupedBets[bet.match]) {
            groupedBets[bet.match] = [];
            bannedBookiesGrouped[bet.match] = new Set();
          }
          groupedBets[bet.match].push(bet);
          ids.push(bet.id);

          if (Array.isArray(bet.banned_bookies)) {
            bet.banned_bookies.forEach((bookie) => bannedBookiesGrouped[bet.match].add(bookie));
          } else if (bet.banned_bookies) {
            bannedBookiesGrouped[bet.match].add(bet.banned_bookies);
          }
        });

        Object.keys(bannedBookiesGrouped).forEach((match) => {
          bannedBookiesGrouped[match] = Array.from(bannedBookiesGrouped[match]).join(', ') || 'None';
        });

        setBetsByMatch(groupedBets);
        setBannedBookiesByMatch(bannedBookiesGrouped);
        setBetIds(ids);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTodaysBetbuilderBets();
  }, []);

  useEffect(() => {
    if (betIds.length > 0) {
      fetchStakes(betIds);
    }
  }, [betIds]);

  const fetchStakes = async (betIds) => {
    try {
      const response = await fetch(`${backendUrl}/bets/get_todays_stakes/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ bet_ids: betIds }),
      });
      const data = await response.json();
      const stakesMap = data.stakes.reduce((acc, stake) => {
        acc[stake.bet_id] = stake.amount;
        return acc;
      }, {});
      setStakes(stakesMap);
    } catch (error) {
      console.error("Error fetching stakes:", error);
    }
  };

  const toggleMatch = (match) => {
    setExpandedMatches((prevExpandedMatches) => ({
      ...prevExpandedMatches,
      [match]: !prevExpandedMatches[match],
    }));
  };

  const handleAddStake = async (betId) => {
    const amount = prompt("Enter stake amount:");
    if (amount !== null && !isNaN(amount) && amount > 0) {
      const userName = localStorage.getItem("userName");
      try {
        const response = await fetch(`${backendUrl}/bets/add_stake/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ bet_id: betId, stake: amount, created_by: userName, type: 'BetBuilder', model: 'Betbuilder' }),
        });
        if (response.ok) {
          fetchStakes(betIds);
        } else {
          const result = await response.json();
          alert(result.error);
        }
      } catch (error) {
        alert("Failed to add stake.");
      }
    }
  };

  if (loading) return <p>Loading today's bets...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="bets-content">
      <table className="bets-table">
        <thead>
          <tr>
            <th>Match</th>
            <th>Market</th>
            <th>Price</th>
            <th>Total Stakes</th>
            <th>Action</th>
            <th>Banned Bookies</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(betsByMatch).length > 0 ? (
            Object.keys(betsByMatch).map((match) => (
              <React.Fragment key={match}>
                <tr className="match-row" onClick={() => toggleMatch(match)}>
                  <td>{match}</td>
                  <td colSpan="4" className="expand-label">
                    {expandedMatches[match] ? 'Hide Selections' : 'Show Selections'}
                  </td>
                  <td>{bannedBookiesByMatch[match]}</td>
                </tr>
                {expandedMatches[match] &&
                  betsByMatch[match].map((bet, index) => (
                    <tr key={index} className="bet-details">
                      <td></td>
                      <td>{bet.market}</td>
                      <td>{bet.price}</td>
                      <td>{stakes[bet.id] ? `$ ${stakes[bet.id]}` : '-'}</td>
                      <td>
                        <button onClick={() => handleAddStake(bet.id)} className="stake-button">
                          Add Stake
                        </button>
                      </td>
                    </tr>
                  ))}
              </React.Fragment>
            ))
          ) : (
            <tr>
              <td colSpan="6">No bets available for today</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default BetsTable;

import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/Dashboard";
import Accounts from "./components/Accounts";
import Wallets from "./components/Wallets";
import Transactions from "./components/Transactions";
import Bets from "./components/Bets";
import Bookmakers from "./components/Bookmakers";
import PrivateRoute from "./components/PrivateRoute";
import Logout from "./components/Logout";

function App() {
  // Function to log out the user
  const handleLogout = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("logoutTime");

    // Redirect to login page (fixes the `useHistory` issue)
    window.location.href = "/login";
  };

  // Function to check if logout time has expired
  const checkLogoutTime = () => {
    const logoutTime = localStorage.getItem("logoutTime");

    if (logoutTime && Date.now() > parseInt(logoutTime)) {
      handleLogout(); // Auto logout user
    }
  };

  useEffect(() => {
    checkLogoutTime(); // Run check on app load

    // Check every minute
    const interval = setInterval(checkLogoutTime, 60000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <Router>
      <div className="App">
        <Switch>
          {/* Public routes */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />

          {/* Protected routes */}
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/accounts" component={Accounts} />
          <PrivateRoute path="/wallets" component={Wallets} />
          <PrivateRoute path="/transactions" component={Transactions} />
          <PrivateRoute path="/bets" component={Bets} />
          <PrivateRoute path="/bookmakers" component={Bookmakers} />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

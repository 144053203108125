import React, { useState, useEffect } from 'react';
import './Bookmakers.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const BookmakersModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    max_balance: '',
    priming: '',
    markets: '',
    no_active_accounts: '',
    kyc_needs: {
      "proof_of_address": false,
      "bank_account_accepted": false,
      "proof_of_funds": false,
      "video_verification": false,
      "liveness_check_required": false,
      "call_required": false
    }
  });

  const [bookmakers, setBookmakers] = useState([]); // Store list of bookmakers from DB
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Function to refetch the bookmakers list
  const fetchBookmakersList = () => {
    fetch(`${backendUrl}/bookmakers/list`) // Adjust to match your actual endpoint
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to fetch bookmakers');
        }
        return response.json();
      })
      .then(data => {
        setBookmakers(data);
      })
      .catch(error => {
        console.error(error);
        setErrorMessage('Failed to load bookmakers.');
      });
  };

  // Fetch the bookmakers list when modal is opened
  useEffect(() => {
    if (isOpen) {
      fetchBookmakersList();
    }
  }, [isOpen]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleKYCChange = (e) => {
    const { name, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      kyc_needs: {
        ...prevState.kyc_needs,
        [name]: checked
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${backendUrl}/bookmakers/add`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setSuccessMessage('Bookmaker added successfully!');
        setErrorMessage('');
        // Refetch the bookmakers list after successful submission
        fetchBookmakersList();
        setTimeout(() => {
          setSuccessMessage('');
          onClose();
        }, 2000);
      } else {
        setErrorMessage('Error adding bookmaker, please try again.');
      }
    } catch (error) {
      setErrorMessage('Error adding bookmaker: ' + error.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>Add Bookmaker</h2>
          <button className="close-button" onClick={onClose}>&times;</button>
        </div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <form className="bookmaker-form" onSubmit={handleSubmit}>
          <label>
            Bookmaker:
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Max funds:
            <input
              type="text"
              name="max_balance"
              value={formData.max_balance}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Priming:
            <input
              type="text"
              name="priming"
              value={formData.priming}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Markets:
            <input
              type="text"
              name="markets"
              value={formData.markets}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            No. Active Accounts:
            <input
              type="text"
              name="no_active_accounts"
              value={formData.no_active_accounts}
              onChange={handleChange}
              required
            />
          </label>

          {/* KYC Needs Section (Checkboxes) */}
          <label>
            KYC Needs:
            <div className="kyc-options">
              {Object.keys(formData.kyc_needs).map((option, index) => (
                <div key={index} className="kyc-checkbox">
                  <input
                    type="checkbox"
                    name={option}
                    checked={formData.kyc_needs[option]}
                    onChange={handleKYCChange}
                  />
                  <label>{option}</label>
                </div>
              ))}
            </div>
          </label>

          <button type="submit" className="save-button">Save</button>
        </form>
      </div>
    </div>
  );
};

export default BookmakersModal;

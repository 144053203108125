import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import './PendingTransactionsModal.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const PendingTransactionsModal = ({ isOpen, onClose }) => {
  const [flaggedTransactions, setFlaggedTransactions] = useState([]);
  const [editingTransactionId, setEditingTransactionId] = useState(null);

  // rowEdits will store per-transaction editing data:
  // {
  //   [tx_id]: {
  //     username: { value: 'someUser', label: 'someUser' } | null,
  //     bookmaker: { value: 'someBookmaker', label: 'someBookmaker' } | null,
  //     usernames: [ ...filtered user options... ],
  //     bookmakers: [ ...filtered bookmaker options... ]
  //   }
  // }
  const [rowEdits, setRowEdits] = useState({});

  const [accounts, setAccounts] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [bookmakers, setBookmakers] = useState([]);

  // Filters for the transactions table
  const [traders, setTraders] = useState([]);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [selectedTrader, setSelectedTrader] = useState('');
  const [selectedWalletAddress, setSelectedWalletAddress] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchFlaggedTransactions();
      fetchAccounts();
    }
  }, [isOpen]);

  const caseInsensitiveFilter = (option, inputValue) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const fetchFlaggedTransactions = async () => {
    try {
      const response = await axios.get(`${backendUrl}/wallet/transactions/query/flagged`);
      if (response.data.success && Array.isArray(response.data.result)) {
        setFlaggedTransactions(response.data.result);

        const uniqueTraders = [...new Set(response.data.result.map((t) => t.trader))];
        const uniqueWallets = [...new Set(response.data.result.map((t) => t.wallet_name))];

        setTraders(uniqueTraders);
        setWalletAddresses(uniqueWallets);
      } else {
        console.error('API Error:', response.data);
        setFlaggedTransactions([]);
      }
    } catch (error) {
      console.error('Error fetching flagged transactions:', error);
    }
  };

  const fetchAccounts = async () => {
    try {
      const response = await axios.get(`${backendUrl}/account/list/`);

      if (Array.isArray(response.data)) {
        setAccounts(response.data);

        // Build global unfiltered username/bookmaker lists
        const uniqueUsernames = new Set(response.data.map(acc => acc.username));
        const uniqueBookmakers = new Set(response.data.map(acc => acc.bookmaker));

        uniqueUsernames.add('Wasabi');
        uniqueBookmakers.add('Wasabi');

        setUsernames(Array.from(uniqueUsernames).map(name => ({ value: name, label: name })));
        setBookmakers(Array.from(uniqueBookmakers).map(bm => ({ value: bm, label: bm })));
      } else {
        console.error('API Error fetching accounts:', response.data);
        setUsernames([]);
        setBookmakers([]);
      }
    } catch (error) {
      console.error('Error fetching accounts:', error);
    }
  };

  // Called when you click "Edit" on a row
  const handleEditClick = (transactionId) => {
    setEditingTransactionId(transactionId);

    // If this row doesn't have an entry yet in rowEdits, initialize it
    setRowEdits(prev => {
      if (prev[transactionId]) {
        return prev; // already has data
      }
      return {
        ...prev,
        [transactionId]: {
          username: null,
          bookmaker: null,
          // start with the *global* sets as the unfiltered defaults
          usernames,
          bookmakers
        },
      };
    });
  };

  // Called when user picks a username in the Select for a specific row
  const handleRowUsernameChange = (txId, selectedOption) => {
    setRowEdits(prev => {
      const rowState = { ...(prev[txId] || {}) };

      rowState.username = selectedOption;

      // Filter bookmakers if a specific username was selected (and not "Wasabi")
      if (selectedOption && selectedOption.value !== "Wasabi") {
        const filteredBookmakers = accounts
          .filter(acc => acc.username === selectedOption.value)
          .map(acc => acc.bookmaker);
        if (!filteredBookmakers.includes("Wasabi")) filteredBookmakers.push("Wasabi");
        rowState.bookmakers = filteredBookmakers.map(bm => ({ value: bm, label: bm }));
      } else {
        // Show all bookmakers if "Wasabi" or no username
        const allBms = new Set(accounts.map(acc => acc.bookmaker));
        allBms.add('Wasabi');
        rowState.bookmakers = Array.from(allBms).map(bm => ({ value: bm, label: bm }));
      }

      return { ...prev, [txId]: rowState };
    });
  };

  // Called when user picks a bookmaker in the Select for a specific row
  const handleRowBookmakerChange = (txId, selectedOption) => {
    setRowEdits(prev => {
      const rowState = { ...(prev[txId] || {}) };

      rowState.bookmaker = selectedOption;

      // Filter usernames if a specific bookmaker was selected (and not "Wasabi")
      if (selectedOption && selectedOption.value !== "Wasabi") {
        const filteredUsernames = accounts
          .filter(acc => acc.bookmaker === selectedOption.value)
          .map(acc => acc.username);
        if (!filteredUsernames.includes("Wasabi")) filteredUsernames.push("Wasabi");
        rowState.usernames = filteredUsernames.map(u => ({ value: u, label: u }));
      } else {
        // Show all usernames if "Wasabi" or no bookmaker
        const allUsers = new Set(accounts.map(acc => acc.username));
        allUsers.add('Wasabi');
        rowState.usernames = Array.from(allUsers).map(u => ({ value: u, label: u }));
      }

      return { ...prev, [txId]: rowState };
    });
  };

  // Called when user clicks "Save" for a specific row
  const handleSaveClick = async (transactionId) => {
    const rowState = rowEdits[transactionId];
    if (!rowState) return;

    const { username, bookmaker } = rowState;

    // If user/bookmaker is "Wasabi", set source to "Wasabi"
    if (username?.value === "Wasabi" || bookmaker?.value === "Wasabi") {
      try {
        await axios.post(`${backendUrl}/wallet/transactions/pending/ammend`, {
          id: transactionId,
          source: "Wasabi",
        });
        setEditingTransactionId(null);
        fetchFlaggedTransactions();
      } catch (error) {
        console.error("Error updating transaction source:", error);
      }
      return;
    }

    // Otherwise, both must be selected
    if (!username || !bookmaker) {
      alert("Please select both a username and a bookmaker.");
      return;
    }

    // Find the account that matches the chosen username/bookmaker
    const matchedAccount = accounts.find(
      acc => acc.username === username.value && acc.bookmaker === bookmaker.value
    );

    if (!matchedAccount) {
      alert("No matching account found for the selected username and bookmaker.");
      return;
    }

    // Update transaction source to the matched account_id
    try {
      await axios.post(`${backendUrl}/wallet/transactions/pending/ammend`, {
        id: transactionId,
        source: matchedAccount.account_id,
      });
      setEditingTransactionId(null);
      fetchFlaggedTransactions();
    } catch (error) {
      console.error("Error updating transaction source:", error);
    }
  };

  // Called when user clicks "Cancel"
  const handleCancelClick = () => {
    setEditingTransactionId(null);
    // Optionally clear rowEdits for that transaction if you like:
    // setRowEdits(prev => {
    //   const newState = { ...prev };
    //   delete newState[editingTransactionId];
    //   return newState;
    // });
  };

  // Filter the displayed transactions by selectedTrader and selectedWalletAddress
  const filteredTransactions = flaggedTransactions.filter(transaction => {
    return (
      (!selectedTrader || transaction.trader === selectedTrader) &&
      (!selectedWalletAddress || transaction.wallet_name === selectedWalletAddress)
    );
  });

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal open">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>X</button>
        <h2>Flagged Transactions</h2>

        {/* Filters at the top */}
        <div className="filters">
          <select value={selectedTrader} onChange={(e) => setSelectedTrader(e.target.value)}>
            <option value="">All Traders</option>
            {traders.map((trader, index) => (
              <option key={index} value={trader}>{trader}</option>
            ))}
          </select>

          <select
            value={selectedWalletAddress}
            onChange={(e) => setSelectedWalletAddress(e.target.value)}
          >
            <option value="">All Wallet Addresses</option>
            {walletAddresses.map((address, index) => (
              <option key={index} value={address}>{address}</option>
            ))}
          </select>
        </div>

        <table className="transactions-table">
          <thead>
            <tr>
              <th>Transaction ID</th>
              <th>Amount</th>
              <th>Trader</th>
              <th>Wallet Name</th>
              <th>Date</th>
              <th>Source</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredTransactions.map(transaction => {
              const txId = transaction.tx_id;
              const rowState = rowEdits[txId] || {};

              return (
                <tr key={txId}>
                  <td>{transaction.tx_id}</td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.trader}</td>
                  <td>{transaction.wallet_name}</td>
                  <td>{transaction.tx_date}</td>
                  <td>
                    {editingTransactionId === txId ? (
                      <>
                        <Select
                          value={rowState.username}
                          onChange={(val) => handleRowUsernameChange(txId, val)}
                          // Use the row-level "usernames" array if it exists, otherwise the global "usernames"
                          options={rowState.usernames || usernames}
                          isSearchable
                          isClearable
                          filterOption={caseInsensitiveFilter}
                        />
                        <Select
                          value={rowState.bookmaker}
                          onChange={(val) => handleRowBookmakerChange(txId, val)}
                          // Use the row-level "bookmakers" array if it exists, otherwise the global "bookmakers"
                          options={rowState.bookmakers || bookmakers}
                          isSearchable
                          isClearable
                          filterOption={caseInsensitiveFilter}
                        />
                        <button onClick={() => handleSaveClick(txId)}>Save</button>
                        <button onClick={handleCancelClick}>Cancel</button>
                      </>
                    ) : (
                      // If not editing, just show the existing source
                      transaction.source
                    )}
                  </td>
                  <td>
                    <button onClick={() => handleEditClick(txId)}>Edit</button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PendingTransactionsModal;

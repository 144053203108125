import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  FaPencilAlt,
  FaSave,
  FaPlus,
  FaTimes,
  FaCheck,
  FaSort,
  FaSortUp,
  FaSortDown,
} from "react-icons/fa";
import axios from "axios";
import "./Dashboard.css";
import logo from "../assets/EnterpriseLogo.png";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Dashboard = () => {
  const history = useHistory();
  const traders = ["SP", "EI", "NB", "AL", "JK", "XT", "EV"]; // For dropdown options

  // State for weekly tasks (main table)
  const [tasks, setTasks] = useState([]);
  const [editingTaskIndex, setEditingTaskIndex] = useState(null);

  // New state for today's total stakes
  const [todayTotals, setTodayTotals] = useState({
    grannit_total: 0,
    betbuilder_total: 0,
  });

  // State for stakes summary per trader (if needed)
  const [grannitStakes, setGrannitStakes] = useState({});
  const [betbuilderStakes, setBetbuilderStakes] = useState({});

  // Fetch weekly tasks from backend
  const fetchTasks = useCallback(async () => {
    try {
      const response = await axios.get(`${backendUrl}/tasks/get`);
      // Since the endpoint returns an array directly, set tasks accordingly
      setTasks(response.data);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  }, []);

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  // Fetch today's total stakes from backend
const fetchTodayTotals = useCallback(async () => {
  try {
    const response = await axios.get(`${backendUrl}/bets/stakes/total/today`);
    const data = response.data;

    console.log("Fetched Total Stakes:", data); // Debugging

    setTodayTotals({
      grannit_total: data.total_grannit ?? 0,
      betbuilder_total: data.total_betbuilder ?? 0,
    });
  } catch (error) {
    console.error("Error fetching today's total stakes:", error);
  }
}, []);


  useEffect(() => {
    fetchTodayTotals();
  }, [fetchTodayTotals]);

  // (Optional) Fetch stakes summary per trader
  useEffect(() => {
const fetchStakes = async () => {
  try {
    const response = await axios.get(`${backendUrl}/bets/stakes/total/today`);
    const data = response.data;

    setTodayTotals({
      grannit_total: data.total_grannit || 0,
      betbuilder_total: data.total_betbuilder || 0,
    });

    setGrannitStakes(data.grannit || {});
    setBetbuilderStakes(data.betbuilder || {});
  } catch (error) {
    console.error("Error fetching stakes summary:", error);
  }
};

    fetchStakes();
  }, []);

  // Add a new task and open it for editing
  const addNewTask = () => {
    setTasks((prev) => [
      ...prev,
      { task: "New Task", assigned_to: "", done: false, comments: "" },
    ]);
    setEditingTaskIndex(tasks.length);
  };

  // Update a specific field for a task
  const handleTaskChange = (index, field, value) => {
    setTasks((prev) => {
      const updated = [...prev];
      updated[index][field] = value;
      return updated;
    });
  };

  // Save the currently edited task to the backend (create or update)
  const saveTask = async (index) => {
    const taskToSave = tasks[index];
    if (!taskToSave.task || !taskToSave.assigned_to) {
      alert("Task and Assigned To fields cannot be empty.");
      return;
    }
    const userName = localStorage.getItem("userName");
    try {
      // Build payload. If task_id exists, it's an update; otherwise, it's a creation.
      const payload = {
        task: taskToSave.task,
        assigned_to: taskToSave.assigned_to,
        done: taskToSave.done,
        created_by: userName,
      };
      if (taskToSave.task_id) {
        payload.task_id = taskToSave.task_id;
      }
      const response = await axios.post(`${backendUrl}/tasks/update`, payload);
      // Replace the task in state with the response from backend
      setTasks((prev) => {
        const updated = [...prev];
        updated[index] = response.data;
        return updated;
      });
      setEditingTaskIndex(null);
    } catch (error) {
      console.error("Error saving task:", error);
    }
  };

  // Toggle task completion and update the backend document
  const toggleTaskDone = async (index) => {
    const taskToUpdate = tasks[index];
    const newDoneStatus = !taskToUpdate.done;
    try {
      await axios.post(`${backendUrl}/tasks/update`, {
        task_id: taskToUpdate.task_id,
        done: newDoneStatus,
      });
      // Update local state if backend update is successful
      setTasks((prev) => {
        const updated = [...prev];
        updated[index].done = newDoneStatus;
        return updated;
      });
    } catch (error) {
      console.error("Error updating task done status:", error);
    }
  };

  // Navigation helper
  const handleNavigation = (path) => history.push(path);

  // Sorting functions for tasks table (if needed)
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const toggleSort = (key) => {
    setSortConfig((prevSort) => ({
      key,
      direction:
        prevSort.key === key && prevSort.direction === "asc" ? "desc" : "asc",
    }));
  };
  const getSortIcon = (column) => {
    if (sortConfig.key !== column) return <FaSort />;
    return sortConfig.direction === "asc" ? <FaSortUp /> : <FaSortDown />;
  };
  const sortedTasks = () => {
    let sortedData = [...tasks];
    if (sortConfig.key) {
      sortedData.sort((a, b) => {
        const aValue = a[sortConfig.key] || "";
        const bValue = b[sortConfig.key] || "";
        if (!isNaN(aValue) && !isNaN(bValue)) {
          return sortConfig.direction === "asc"
            ? Number(aValue) - Number(bValue)
            : Number(bValue) - Number(aValue);
        }
        return sortConfig.direction === "asc"
          ? aValue.toString().localeCompare(bValue.toString())
          : bValue.toString().localeCompare(aValue.toString());
      });
    }
    return sortedData;
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        <Link to="/dashboard" className="dashboard-logo-link">
          <img className="dashboard-logo" src={logo} alt="Logo" />
        </Link>
        <Link to="/dashboard" className="dashboard-header-link">
          <h1>The Enterprise</h1>
        </Link>
      </div>

      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation("/bookmakers")}>
          Bookies
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/accounts")}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/wallets")}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/transactions")}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/bets")}>
          Bets
        </button>
        <button className="nav-button" onClick={() => handleNavigation("/logout")}>
          Logout
        </button>
      </div>
      <hr className="dashboard-line" />

      <div className="dashboard-content">
        {/* Large Weekly Tasks Table */}
        <div className="large-table-container">
          <table className="large-table">
            <colgroup>
              <col style={{ width: "70%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "15%" }} />
            </colgroup>
            <thead>
              <tr className="tab-row">
                <th colSpan="3">
                  Weekly Tasks
                  <FaPlus className="add-row-icon" onClick={addNewTask} title="Add new task" />
                </th>
              </tr>
              <tr>
                <th onClick={() => toggleSort("task")} style={{ cursor: "pointer" }}>
                  Task {getSortIcon("task")}
                </th>
                <th onClick={() => toggleSort("assigned_to")} style={{ cursor: "pointer" }}>
                  Assigned To {getSortIcon("assigned_to")}
                </th>
                <th onClick={() => toggleSort("done")} style={{ cursor: "pointer" }}>
                  Done {getSortIcon("done")}
                </th>
              </tr>
            </thead>
            <tbody>
              {sortedTasks().map((task, index) => (
                <tr key={index}>
                  <td className="task-column">
                    {editingTaskIndex === index ? (
                      <>
                        <input
                          type="text"
                          value={task.task}
                          onChange={(e) => handleTaskChange(index, "task", e.target.value)}
                        />
                        <FaSave
                          onClick={() => saveTask(index)}
                          className="edit-save-icon"
                          title="Save task"
                        />
                      </>
                    ) : (
                      <>
                        {task.task}
                        <FaPencilAlt
                          onClick={() => setEditingTaskIndex(index)}
                          className="edit-icon"
                          title="Edit task"
                        />
                      </>
                    )}
                  </td>
                  <td className="assigned-column">
                    {editingTaskIndex === index ? (
                      <select
                        value={task.assigned_to}
                        onChange={(e) =>
                          handleTaskChange(index, "assigned_to", e.target.value)
                        }
                      >
                        <option value="">Select Trader</option>
                        {traders.map((trader) => (
                          <option key={trader} value={trader}>
                            {trader}
                          </option>
                        ))}
                      </select>
                    ) : (
                      task.assigned_to || "Unassigned"
                    )}
                  </td>
                  <td className="done-column">
                    <span
                      className="task-done"
                      onClick={() => toggleTaskDone(index)}
                      style={{ color: task.done ? "green" : "red", cursor: "pointer" }}
                    >
                      {task.done ? <FaCheck /> : <FaTimes />}
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Small Summary Tables */}
        <div className="small-tables-container">
          {/* Total Stakes Table */}
          <table className="small-table">
            <thead>
              <tr>
                <th colSpan="2">Total Stakes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Γρανίτης:</td>
                <td>$ {todayTotals.grannit_total.toFixed(2)}</td>
              </tr>
              <tr>
                <td>BetBuilders:</td>
                <td>$ {todayTotals.betbuilder_total.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>

          {/* Grannit Stakes per Trader */}
          <table className="small-table">
            <thead>
              <tr>
                <th colSpan="2">Γρανίτης Stakes per Trader</th>
              </tr>
            </thead>
            <tbody>
              {traders.map((trader) => (
                <tr key={trader}>
                  <td>{trader}</td>
                  <td>$ {grannitStakes[trader] !== undefined ? grannitStakes[trader] : 0}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* BetBuilder Stakes per Trader */}
          <table className="small-table">
            <thead>
              <tr>
                <th colSpan="2">BetBuilder Stakes per Trader</th>
              </tr>
            </thead>
            <tbody>
              {traders.map((trader) => (
                <tr key={trader}>
                  <td>{trader}</td>
                  <td>$ {betbuilderStakes[trader] !== undefined ? betbuilderStakes[trader] : 0}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useCallback, useEffect, useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import './Transactions.css';
import logo from '../assets/EnterpriseLogo.png';
import TransactionsModal from './TransactionsModal';
import PendingTransactionsModal from './PendingTransactionsModal';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Transactions = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPendingModalOpen, setIsPendingModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [transactions, setTransactions] = useState([]);
  const [isPendingOnly, setIsPendingOnly] = useState(false);

  const fetchTransactions = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${backendUrl}/wallet/transactions/query`);
      if (response.data.success) {
        setTransactions(response.data.result);
      } else {
        console.error('API Error:', response.data);
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching Transactions:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    history.push('/logout');
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openPendingModal = () => {
    setIsPendingModalOpen(true);
  };

  const closePendingModal = () => {
    setIsPendingModalOpen(false);
  };

  const handleAddTransaction = () => {
    openModal();
  };

  const handleUpdateTransaction = () => {
    openPendingModal();
  };

  const handlePendingChange = (e) => {
    setIsPendingOnly(e.target.checked);
  };

  const [expandedId, setExpandedId] = useState(null);

  const toggleTransactionId = (tx_id) => {
    setExpandedId(expandedId === tx_id ? null : tx_id);
  };

  const filteredTransactions = Array.isArray(transactions)
    ? transactions.filter((transaction) => {
        return !isPendingOnly || transaction.tx_id === 'Pending';
      })
    : [];

  return (
    <div className="dashboard">
      <div className="dashboard-header">
    <Link to="/dashboard" className="dashboard-logo-link">
      <img className="dashboard-logo" src={logo} alt="Logo" />
    </Link>
        <Link to="/dashboard" className="dashboard-header-link">
      <h1>The Enterprise</h1>
    </Link>
      </div>
      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation("/bookmakers")}>
          Bookies
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/accounts')}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/wallets')}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/transactions')}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/bets')}>
          Bets
        </button>
        <button className="nav-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <button className="add-transaction-button" onClick={handleAddTransaction}>
        + Add Pending Transaction
      </button>
      <button className="update-transaction-button" onClick={handleUpdateTransaction}>
        + Update Pending Transaction
      </button>

      <hr className="dashboard-line" />

      <TransactionsModal isOpen={isModalOpen} onClose={closeModal} />
      <PendingTransactionsModal isOpen={isPendingModalOpen} onClose={closePendingModal} />

      <div className="filter-section">
        <label>
          <input type="checkbox" checked={isPendingOnly} onChange={handlePendingChange} />
          Pending
        </label>
      </div>

      <div className="transactions-container">
        {isLoading ? (
          <p>Loading Transactions...</p>
        ) : (
          <table className="transactions-table">
            <thead>
              <tr>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Type</th>
                <th>Wallet Address</th>
                <th>Account Holder</th>
                <th>Source</th>
              </tr>
            </thead>
            <tbody>
              {filteredTransactions.map((transaction) => (
                <tr key={transaction.tx_id}>
                  <td
                    onDoubleClick={() => toggleTransactionId(transaction.tx_id)}
                    title={expandedId === transaction.tx_id ? transaction.tx_id : ''}
                  >
                    {expandedId === transaction.tx_id
                      ? transaction.tx_id
                      : `${transaction.tx_id.slice(0, 10)}...`}
                  </td>
                  <td>{transaction.amount}</td>
                  <td>{transaction.incoming ? 'Incoming' : 'Outgoing'}</td>
                  <td>{transaction.wallet_address}</td>
                  <td>{transaction.account_holder}</td>
                  <td>{transaction.source}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Transactions;

// frontend/src/components/Bets.js
import React, { useState } from 'react';
import {Link, useHistory} from 'react-router-dom';
import './Bets.css';
import logo from '../assets/EnterpriseLogo.png';
import BetsTable from './BetsTable';          // Existing Bets table
import BetbuilderTable from './BetbuilderTable'; // New Betbuilder table

const Bets = () => {
  const history = useHistory();
  // State to track which tab is active
  const [activeTab, setActiveTab] = useState('granite');

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleLogout = () => {
    history.push('/logout');
  };

  return (
    <div className="dashboard">
      <div className="dashboard-header">
    <Link to="/dashboard" className="dashboard-logo-link">
      <img className="dashboard-logo" src={logo} alt="Logo" />
    </Link>
        <Link to="/dashboard" className="dashboard-header-link">
      <h1>The Enterprise</h1>
    </Link>
      </div>

      <div className="dashboard-nav">
        <button className="nav-button" onClick={() => handleNavigation("/bookmakers")}>
          Bookies
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/accounts')}>
          Accounts
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/wallets')}>
          Wallets
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/transactions')}>
          Transactions
        </button>
        <button className="nav-button" onClick={() => handleNavigation('/bets')}>
          Bets
        </button>
        <button className="nav-button" onClick={handleLogout}>
          Logout
        </button>
      </div>

      <hr className="dashboard-line" />

      {/* Tab bar for Grannit Bets vs Betbuilder */}
      <div className="bets-tabs">
        <button
          className={`bets-tab-button ${activeTab === 'granite' ? 'active' : ''}`}
          onClick={() => setActiveTab('granite')}
        >
          Γρανίτης
        </button>
        <button
          className={`bets-tab-button ${activeTab === 'betbuilder' ? 'active' : ''}`}
          onClick={() => setActiveTab('betbuilder')}
        >
          Betbuilder
        </button>
      </div>

      {/* Conditional rendering based on activeTab */}
      <div className="bets-content">
        {activeTab === 'granite' && <BetsTable />}
        {activeTab === 'betbuilder' && <BetbuilderTable />}
      </div>
    </div>
  );
};

export default Bets;

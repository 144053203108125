import React, { useState, useEffect } from 'react';
import './AccountModal.css';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AccountModal = ({ isOpen, onClose }) => {
    const [formData, setFormData] = useState({
        bookmaker: '',
        username: '',
        password: '',
        email: '',
        account_holder: '',
        kyc_passed: '',
        vpn_used: '',
        vpn_country_used: '',
        trader:''
    });

    const [bookmakers, setBookmakers] = useState([]); // Store list of bookmakers from DB
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    // Fetch the bookmakers list on component mount
    useEffect(() => {
        if (isOpen) {
            fetch(`${backendUrl}/bookmakers/list`) // Adjust to match your actual endpoint
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch bookmakers');
                    }
                    return response.json();
                })
                .then(data => {
                    // data should be an array of bookmakers
                    setBookmakers(data);
                })
                .catch(error => {
                    console.error(error);
                    setErrorMessage('Failed to load bookmakers.');
                });
        }
    }, [isOpen]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendUrl}/account/add/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setSuccessMessage('Account added successfully!');
                setErrorMessage('');
                setTimeout(() => {
                    setSuccessMessage('');
                    onClose();
                }, 2000);
            } else {
                setErrorMessage('Error adding account, please try again.');
            }
        } catch (error) {
            setErrorMessage('Error adding account: ' + error.message);
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <div className="modal-header">
                    <h2>Add Account</h2>
                    <button className="close-button" onClick={onClose}>&times;</button>
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}

                <form className="account-form" onSubmit={handleSubmit}>
                    {/* 1) Use <select> instead of <input type="text"> for bookmaker */}
                    <label>
                        Bookmaker:
                        <select
                            name="bookmaker"
                            value={formData.bookmaker}
                            onChange={handleChange}
                            required
                            className="account-input"
                        >
                            <option value="">-- Select a bookmaker --</option>
                            {bookmakers.map((bm, idx) => (
                                // Adjust bm._id, bm.name or your data fields accordingly
                                <option key={idx} value={bm}>
                                    {bm}
                                </option>
                            ))}
                        </select>
                    </label>

                    <label>
                        Username:
                        <input
                            type="text"
                            name="username"
                            value={formData.username}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Password:
                        <input
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Email:
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Account Holder:
                        <input
                            type="text"
                            name="account_holder"
                            value={formData.account_holder}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        KYC passed:
                        <input
                            type="text"
                            name="kyc_passed"
                            value={formData.kyc_passed}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        Trader:
                        <input
                            type="text"
                            name="trader"
                            value={formData.trader}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        VPN Used:
                        <input
                            type="text"
                            name="vpn_used"
                            value={formData.vpn_used}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <label>
                        VPN Country Used:
                        <input
                            type="text"
                            name="vpn_country_used"
                            value={formData.vpn_country_used}
                            onChange={handleChange}
                            required
                        />
                    </label>
                    <button type="submit" className="save-button">Save</button>
                </form>
            </div>
        </div>
    );
};

export default AccountModal;
